<template>
  <div class="title">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Title",
};
</script>

<style lang="scss">
@import "../styles/colors";

.title {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: color(white, base);

  > h1 {
    font-size: 2.5rem;
    font-weight: 600;
  }

  > h2 {
    font-size: 1.5rem;
  }

  > h3 {
    font-size: 1.125rem;
  }

  > h4 {
    font-weight: 500;
    font-size: 1rem;
  }

  .title__label {
    margin-top: 0.5rem;
    font-weight: 500;
    color: color(blue, lighter);
  }
}
</style>
